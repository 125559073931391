import React from "react";
import ClaimTabHeader from "../layout/TabHeader";

const PersonalAccident = () => {
    return (
        // you must add class group to open/close this container with tailwind
        <div className="border-b outline-none group" tabIndex="2">
            {/* tab flex container */}
            <ClaimTabHeader title="Personal Accident Claim Form"/>
            { /* tab inner content */}
            <div className="claims-tab-inner-content">
                <h6>This form should be filled by the claimant and the attending doctor</h6>
                <div className="py-3">
                    <a className="claim-tab-link" title="Download Personal Accident Claim Form"
                        href="/assets/claim-forms/Personal_Accident_Claim_Form.pdf" 
                        target="_blank" rel="noreferrer">Personal Accident Claim Form</a>
                </div>
            </div>
        </div>
    )
}
export default PersonalAccident;