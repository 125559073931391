import React from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import MainBody from "../layout/ClaimsMainBody";
// claim docs components
import CriticalIllness from "./CriticalIllness";
import Death from "./Death";
import PersonalAccident from "./PersonalAccident";

const Home = () => {
    return (
        <>
            <Header />
            <MainBody>
                {/* accordion container */}
                <div className="max-w-full overflow-hidden">
                    <CriticalIllness />
                    <PersonalAccident />
                    <Death />
                </div>
            </MainBody>
            <Footer />
        </>
    )
}
export default Home;