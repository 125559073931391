import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
    return (
        <div className="main-header">
            <div className="container flex items-center justify-between mx-auto">
                <div className="">
                    <Link to="/">
                        <img src="/images/prulogo-01.png" alt="Prudential Kenya"className="mt-3"  width="100px" height="50px;"/>
                    </Link>
                </div>
                <div className="flex flex-row items-center mr-2 md:mr-0">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                        </svg>
                        <div className="font-semibold">Call: 0719 075 000</div>
                    {/* not required for now */}
                    <Link to="/talk-to-us" className="hidden">Talk to Us</Link>
                </div>
            </div>
        </div>
    )
}
export default Header;