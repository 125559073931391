import React from "react";

// tab header with heading and expand button
const TabHeader = props => {
    return (
        <div className="flex items-center justify-between py-3 text-gray-500 transition duration-500 cursor-pointer
            group ease-in">
            { /* tab title  */}
            <div className="transition duration-500 ease-in group-hover:text-red-500 font-semibold 
                group-focus:underline group-focus:font-bold">
                {props.title}
            </div>
            { /* arrow  */}
            <div className="transition duration-500 ease-in group-focus:-rotate-180 group-focus:text-red-500">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12">
                    <path fill="none" stroke="currentColor" strokeWidth="3" d="M1 1l8 8 8-8"/>
                </svg>
            </div>
        </div>
    )
}
export default TabHeader;