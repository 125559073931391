import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./claims/Home";
import Feedback from "./feedback/Feedback";
import MiniHome from "./miniapp/MiniHome";
import TestProbe from "./test/Index";

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/talk-to-us" element={<Feedback/>}/>
                <Route path="/mini-app" element={<MiniHome/>}/>
                <Route path="/healthcheck" element={<TestProbe/>}/>
            </Routes>
        </BrowserRouter>
    )
}
export default App;