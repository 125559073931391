import React from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import MainBody from "../layout/MiniMainBody";
import MiniProduct from "./MiniProduct";

const MiniHome = () => {
    return (
        <>
            <Header/>
            <MainBody>
                {/* accordion container */}
                <div className="max-w-full overflow-hidden">
                    <MiniProduct tab_index="1" product="PruCritical Care" brochure="prucriticalcare_brochure.pdf"
                        terms_conditions="prucriticalcare.pdf"/>
                    <MiniProduct tab_index="2" product="PruTect" brochure="prutect_brochure.pdf" terms_conditions="prutect.pdf"/>
                    <MiniProduct tab_index="3" product="Hospicash" brochure="hospicash.pdf" terms_conditions="hospicash.pdf"/>
                </div>
                
            </MainBody>
            <Footer/>
        </>
       
    )
}
export default MiniHome;
