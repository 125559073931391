import React from "react";
import ClaimTabHeader from "../layout/TabHeader";

const CriticalIllness = () => {
    return (
        // you must add class group to open/close this container with tailwind
        <div className="border-b outline-none group" tabIndex="1">
            {/* tab flex container */}
            <ClaimTabHeader title="Critical Illness Claim Form"/>
            { /* tab inner content */}
            <div className="claims-tab-inner-content">
                <h6>This form should be filled by the attending physician</h6>
                <div className="py-3">
                    <a className="claim-tab-link" title="Download Critical Illness Claim Form" 
                        href="/assets/claim-forms/Critical_Illness_Claim_Form.pdf" 
                        target="_blank" rel="noreferrer"
                        >
                        Critical Illness Claim Form</a>
                </div>
            </div>
        </div>
    )
}
export default CriticalIllness;