import React from "react";
import { Link } from "react-router-dom";
import Header from "../layout/Header";
import Footer from "../layout/Footer";

const Feedback = () => {
    return (
        <>
            <Header/>
            <div className="container flex flex-col mx-auto mt-10">
                {/* heading container */}
                <div className="flex items-center justify-between">
                    <h4>Talk to us</h4>
                    <div className="flex flex-row items-center space-x-1">
                        <Link to="/">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                            </svg>
                        </Link>
                        <Link to="/">Back</Link>
                    </div>
                </div>
                <div className="flex py-3">
                </div>
                <h6 className="mt-5">Feedback Form</h6>
            </div>
            <Footer/>
        </>
    )
}
export default Feedback;