import React from "react";
import ProductTabHeader from "../layout/TabHeader";

const MiniProduct = props => {
    const brochure = (props.brochure && props.brochure.length > 0) ? props.brochure : null;
    const t_and_cs = (props.terms_conditions && props.terms_conditions.length > 0) ? props.terms_conditions : null;
   
    return (
        // you must add class group to open/close this container with tailwind
        <div className="border-b outline-none group" tabIndex={props.tab_index}>
            {/* tab flex container */}
            <ProductTabHeader title={props.product}/>
            { /* tab inner content */}
            <div className="claims-tab-inner-content">
                <div className="flex flex-col">
                    {brochure !== null &&
                        <span className="mb-2">
                            <a className="claim-tab-link" title="Download Product Brochure" 
                                href={`/assets/miniapp/brochure/${brochure}`}
                                target="_blank" rel="noreferrer"
                                >Product Brochure</a>
                        </span> 
                    }
                    {t_and_cs !== null &&
                        <span className="">
                            <a className="claim-tab-link" title="Download Product Terms and Conditions" 
                                href={`/assets/miniapp/terms_conditions/${t_and_cs}`}
                                target="_blank" rel="noreferrer"
                                >Product Terms and Conditions</a>
                        </span>
                    }
                </div>
            </div>
        </div>
    )
}
export default MiniProduct;