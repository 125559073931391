import React from "react";

const Footer = () => {
    return (
        <footer className="absolute w-full bottom-0 bg-veryDarkBlue py-4 text-white">
            <div className="container flex flex-col items-center justify-between mx-auto md:flex-row">
                <a rel="noreferrer" className="hover:text-cyan-500 hover:underline" href="https://www.prudentiallife.co.ke/" target="_blank">Prudential Life Assurance Kenya Ltd.</a>
                <div>Email us: <span className="mx-2">customer.service@prudentiallife.co.ke</span></div>
            </div>
        </footer>
    )
}
export default Footer;