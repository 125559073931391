import React from "react";
import ClaimTabHeader from "../layout/TabHeader";

const Death = () => {
    return (
        // you must add class group to open/close this container with tailwind
        <div className="border-b outline-none group" tabIndex="3">
            {/* tab flex container */}
            <ClaimTabHeader title="Death Claim Forms"/>
            { /* tab inner content */}
            <div className="claims-tab-inner-content">
                <h6>These forms are required for death claims</h6>
                <div className="flex flex-col py-3">
                    <h5 className="death-claim-form-header">Form 1</h5>
                    <h6 className="px-10 py-2">This form should be filled by the beneficiary</h6>
                    <div className="px-10 mb-2">
                        <a className="claim-tab-link" title="Download Death Claim Form 1" 
                            href="/assets/claim-forms/Death_Claim_Form_1.pdf"
                            target="_blank" rel="noreferrer">Death Claim Form 1</a>
                    </div>
                    <h5 className="death-claim-form-header">Form 2</h5>
                    <h6 className="px-10 py-2">This form should be filled by the attending physician</h6>
                    <div className="px-10 mb-2">
                        <a className="claim-tab-link" title="Download Death Claim Form 2" 
                            href="/assets/claim-forms/Death_Claim_Form_2.pdf"
                            target="_blank" rel="noreferrer">Death Claim Form 2</a>
                    </div>
                    <h5 className="death-claim-form-header">Form 3</h5>
                    <h6 className="px-10 py-2">This form should be filled by the witness</h6>
                    <div className="px-10 mb-2">
                        <a className="claim-tab-link" title="Download Death Claim Form 3" 
                            href="/assets/claim-forms/Death_Claim_Form_3.pdf"
                            target="_blank" rel="noreferrer"
                            >Death Claim Form 3</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Death;