import React from "react";

const ClaimsMainBody = props => {
    return (
        // this will be a wrapper component
        <div className="container flex flex-col mx-auto mt-3 md:mt-6">
            <h5 className="ml-2 mr-2 text-xl font-bold md:ml-0 md:mr-0">Prudential Life Assurance Kenya</h5>
            <h6 className="ml-2 mr-2 text-lg font-semibold md:ml-0 md:mr-0">Claims Documents</h6>
            <div className="border-b border-gray-400"></div>
            <div className="py-3 ml-2 mr-2 md:py-4 md:ml-0 md:mr-0">
                {props.children}
            </div>
        </div>
    )
}
export default ClaimsMainBody;